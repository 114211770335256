<script context="module" lang="ts">
  export interface Testimonial {
    name: string | null;
    body: string | null;
  }
</script>

<script lang="ts">
  import emblaCarouselSvelte from "embla-carousel-svelte";
  import autoPlay from "embla-carousel-autoplay";

  export let testimonials: Testimonial[] = [];

  const plugins = [autoPlay()];

  let currentIndex = 0;

  function onInit(event) {
    const embla = event.detail;
    embla.on("select", () => {
      currentIndex = embla.selectedScrollSnap();
    });
  }
</script>

{#if testimonials.length}
  <div class="m-auto max-w-16 border-dotted border-t-4 border-p400 mb-6" />
  <div
    use:emblaCarouselSvelte={{
      options: {
        loop: true,
        active: testimonials.length > 1,
      },
      plugins: testimonials.length > 1 ? plugins : [],
    }}
    on:emblaInit={onInit}
    class="embla"
  >
    <div class="embla__container">
      {#each testimonials as { name, body }}
        {#if body}
          <div class="embla__slide">
            <p
              class="text-center text-white italic text-body-base font-thin mb-4"
            >
              “{body}”
            </p>
            {#if name}
              <p class="text-center text-n80 font-bolder">– {name}</p>
            {/if}
          </div>
        {/if}
      {/each}
    </div>
  </div>

  <!-- Navigation dots -->
  {#if testimonials.length > 3}
    <div class="flex justify-center gap-2 mt-4">
      {#each testimonials as _, i}
        <button
          class="w-1 h-1 rounded-full transition-all duration-200 bg-n80"
          class:active={currentIndex === i}
        />
      {/each}
    </div>
  {/if}
{/if}

<style>
  .embla {
    overflow: hidden;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }

  /* Navigation dot styles */
  .active {
    @apply bg-white transition-all duration-200 transform scale-[2];
  }
</style>
